<template>
  <div class="public">
    <div class="container HomePage">
      <!--   四码图   -->
      <div class="idiot-one ">
        <div class="item-left">
          <div class="item-left-top" v-for="(val, ind) in boxLeftData" :key="ind" >
            {{ val.name }}：<span>{{ val.val }}<span class="unit">{{ val.unit }}</span></span>
          </div>
        </div>
        <div class="item-center">
          <div>
            总任务
            <p>{{ this.boxCenterData }}<span>件</span></p>
          </div>
        </div>
        <div class="item-right">
          <div class="item-right-top" v-for="(val, ind) in boxRightData" :key="ind" >
            {{ val.name }}：<span>{{ val.val }}<span class="unit">个</span></span>
          </div>
        </div>
      </div>
      <!--   双饼图   -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同分布图</span>
          </div>
        </div>
        <div class="tab-box">
          <el-radio-group v-model="tabPosition1" class="tab-btns">
            <el-radio-button label="1">任务</el-radio-button>
            <el-radio-button label="2">人员</el-radio-button>
          </el-radio-group>
        </div>

        <div class="pie-charts">
          <PieChart
            :chartData=" tabPosition1 == 1 ? pieChartData1 : pieChartData2"
            :titleName="pietitleName"
          ></PieChart>
        </div>

        <div
          class="pie-rwfb"
          :style="{ display: tabPosition1 == 1 ? 'block' : 'none' }"
        >
          <div class="pie-rwfb-top">
            <div class="pie-rwfb-top-1 pie-rwfb-top-indent">部门</div>
            <div class="pie-rwfb-top-2">任务量(件)</div>
            <div class="pie-rwfb-top-2">待执行(件)</div>
            <div class="pie-rwfb-top-2">执行中(件)</div>
            <div class="pie-rwfb-top-2">已执行(件)</div>
          </div>
          <div
            class="pie-rwfb-val"
            v-for="(item, ind) in newpieChartData1"
            :key="item.name"
          >
            <div class="pie-rwfb-val-name">
              <div
                class="pie-rwfb-val-icon"
                :style="{ background: colors[ind] }"
              ></div>
              {{ item.name }}
            </div>
            <div class="pie-rwfb-val-val">{{ item.val1 }}</div>
            <div class="pie-rwfb-val-val">{{ item.val2 }}</div>
            <div class="pie-rwfb-val-val">{{ item.val3 }}</div>
            <div class="pie-rwfb-val-val">{{ item.val4 }}</div>
          </div>
        </div>
        <div
          class="pie-rwfb"
          :style="{ display: tabPosition1 == 2 ? 'block' : 'none' }"
        >
          <div class="pie-rwfb-top">
            <div class="pie-rwfb-top-1 pie-rwfb-top-indent">部门</div>
            <div class="pie-rwfb-top-1">人员数量(人)</div>
            <div class="pie-rwfb-top-1 pie-rwfb-top-indent">部门</div>
            <div class="pie-rwfb-top-1">人员数量(人)</div>
          </div>
          <div class="pie-rwfb-box">
            <div
              class="pie-rwfb-val"
              v-for="(item, ind) in newpieChartData2"
              :key="item.name"
            >
              <div class="pie-rwfb-val-name">
                <div
                  class="pie-rwfb-val-icon"
                  :style="{ background: colors[ind] }"
                ></div>
                {{ item.name }}
              </div>
              <div class="pie-rwfb-val-val">{{ item.val1 }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ typeName1 }}协同新增变化趋势</span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal"></KcSelect>
        </div>
        <div class="tab-box">
          <el-radio-group v-model="tabPosition2" class="tab-btns">
            <el-radio-button label="1">任务</el-radio-button>
            <el-radio-button label="2">人员</el-radio-button>
          </el-radio-group>
        </div>
        <div class="charts">
          <KcSelect
            isBlue
            :optionData="sectionList"
            left="10"
            top="35"
            @popupVal="popupVal5"
            class="kselect"
          ></KcSelect>

          <line-chart
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData1"
            :legendData="legendData1"
            :units="unitData1"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>{{ typeName2 }}协同累计变化趋势</span>
          </div>

          <KcSelect left="0" top="18" @popupVal="popupVal2"></KcSelect>
        </div>
        <div class="tab-box">
          <el-radio-group v-model="tabPosition3" class="tab-btns">
            <el-radio-button label="1">任务</el-radio-button>
            <el-radio-button label="2">人员</el-radio-button>
          </el-radio-group>
        </div>
        <div class="charts">
          <KcSelect
            isBlue
            :optionData="sectionList"
            right="10"
            top="-35"
            @popupVal="popupVal6"
            class="kselect"
          ></KcSelect>
          <line-chart
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData2"
            :legendData="legendData2"
            :units="unitData2"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同区域分布数量统计</span>
          </div>
          <KcSelect
            isBlue
            :optionData="sectionList"
            right="10"
            top="-35"
            @popupVal="popupVal3"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed="left"
            >
            </el-table-column>
            <template>
              <el-table-column prop="money" :key="1" label="协同部门" sortable :width="0.25 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.deptNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="custodyMoney"
                  :key="2"
                  label="执行人员"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.userNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  :key="3"
                  label="总任务"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskTotalNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  :key="4"
                  label="待执行"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskStandByNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  :key="5"
                  label="执行中"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskUnderWayNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  :key="6"
                  label="已执行"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskCompletedNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 任务协同按领域/行业统计 （不按领域统计） 表格 -->
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line"; // 四码折线图
import Pmap from "@/components/charts/map.vue"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import Toptab from "@/components/toptab.vue";
import PieChart from "@/components/charts/PieSupervise"; //饼图
import {
  statisticsTaskStatus,
  taskSituationByDep,
  departmentList,
  taskAdd,
  taskCumulative,
  taskCityStatistics,
} from "@/api/homeTaskCoordination.js";

export default {
  name: "HomePage",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      lineChart1: false,
      lineChart2: false,
      colors: [
        "#76fdfc",
        "#51f1fe",
        "#4effe8",
        "#01ffb3",
        "#00d489",
        "#d8ff85",
        "#eeeb47",
        "#ffd645",
        "#ff3567",
        "#e759b1",
        "#b95af2",
        "#6f5af2",
        "#0073ff",
        "#3398ff",
        "#05b5ff",
        "#70ddfe",
        "#76e8fd",
      ],
      hairpinType1: "",
      hairpinType2: "",
      hairpinType3: "",
      hairpinType4: "",
      sectionList: [],
      radio1: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [],
      },
      radio2: "1",
      lineChartData2: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      baidumapOptions: [],
      forCodeData: [],
      dataregion: [],
      tabPosition1: 1,
      tabPosition2: 1,
      tabPosition3: 1,
      newpieChartData1: [
        {
          name: "政法委",
          val1: "1",
          val2: "1",
          val3: "1",
          val4: "1",
        },
        {
          name: "政府办",
          val1: "2",
          val2: "2",
          val3: "2",
          val4: "2",
        },
      ],
      newpieChartData2: [
        {
          name: "政法委",
          val1: "2",
        },
        {
          name: "政府办",
          val1: "2",
        },
        {
          name: "信访办",
          val1: "2",
        },
        {
          name: "公安分局",
          val1: "2",
        },
        {
          name: "市场监管局",
          val1: "2",
        },
      ],
      pietitleName: "任务分布",

      typeName1: "任务",
      typeName2: "任务",
      legendData2: ["总任务", "待执行", "执行中", "已执行"],
      unitData2: ["件", "件", "件", "件"],
      legendData1: ["总任务", "待执行", "执行中", "已执行"],
      unitData1: ["件", "件", "件", "件"],
      boxLeftData: [
        {
          name: "协同部门",
          val: "0",
          unit: "个",
        },
        {
          name: "执行人员",
          val: "0",
          unit: "人",
        },
      ],
      boxCenterData: 0,
      boxRightData: [
        {
          name: "待执行",
          val: "0",
          unit: "件",
        },
        {
          name: "执行中",
          val: "0",
          unit: "件",
        },
        {
          name: "已执行",
          val: "0",
          unit: "件",
        },
      ],
      pieChartData1: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.taskAdd();
    },
    radio2: function (val) {
      this.taskCumulative();
    },
    hairpinType1: function (val) {
      this.taskCityStatistics();
    },

    hairpinType3: function (val) {
      this.taskAdd();
    },
    hairpinType4: function (val) {
      this.taskCumulative();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
    tabPosition1: function (val) {
      this.pietitleName = this.tabPosition1 == 1 ? "任务分布" : "人员分布";
      this.taskSituationByDep();
    },
    tabPosition2: function (val) {
      this.legendData1 =
        val == 1 ? ["总任务", "待执行", "执行中", "已执行"] : ["执行人员"];
      this.unitData1 = val == 1 ? ["件", "件", "件", "件"] : [""];
      this.typeName1 = val == 1 ? "任务" : "人员";
      this.taskAdd();
    },
    tabPosition3: function (val) {
      this.legendData2 =
        val == 1 ? ["总任务", "待执行", "执行中", "已执行"] : ["执行人员"];
      this.unitData2 = val == 1 ? ["件", "件", "件", "件"] : [""];
      this.typeName2 = val == 1 ? "任务" : "人员";
      this.taskCumulative();
    },
  },
  methods: {
    popupVal(val) {
      this.radio1 = val;
    },
    popupVal2(val) {
      this.radio2 = val;
    },
    popupVal3(val) {
      this.hairpinType1 = val;
    },
    popupVal4(val) {
      this.hairpinType2 = val;
    },
    popupVal5(val) {
      this.hairpinType3 = val;
    },
    popupVal6(val,ind) {
      this.hairpinType4 = val;
    },

    intData() {
      this.departmentList();
      this.statisticsTaskStatus();
      this.taskAdd();
      this.taskCumulative();
      this.taskCityStatistics();
      this.taskSituationByDep();
    },
    taskSituationByDep() {
      let _this = this;
      taskSituationByDep({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        console.log("饼图", res);
        this.pieChartData2.seriesData = res.data.map((v) => {
          return {
            name: v.departmentName,
            value: v.userNum,
            com: "人",
          };
        });
        this.newpieChartData2 = res.data.map((v) => {
          return {
            name: v.departmentName,
            val1: v.userNum,
          };
        });
        this.pieChartData1.seriesData = res.data.map((v) => {
          return {
            name: v.departmentName,
            value: v.userNum,
            pending: v.taskStandByNum,
            processing: v.taskUnderWayNum,
            processed: v.taskCompletedNum,
          };
        });
        this.newpieChartData1 = res.data.map((v) => {
          return {
            name: v.departmentName,
            val1: v.userNum,
            val2: v.taskStandByNum,
            val3: v.taskUnderWayNum,
            val4: v.taskCompletedNum,
          };
        });
      });
    },

    departmentList() {
      departmentList().then((res) => {
        res.data.unshift({ name: "全部", id: "" });
        this.sectionList = res.data;
      });
    },

    statisticsTaskStatus() {
      statisticsTaskStatus({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            console.log(res.data);
            this.boxLeftData[0].val = res.data.departmentNum;
            this.boxLeftData[1].val = res.data.userNum;
            this.boxRightData[0].val = res.data.taskStandByNum;
            this.boxRightData[1].val = res.data.taskUnderWayNum;
            this.boxRightData[2].val = res.data.taskCompletedNum;
            this.boxCenterData = res.data.taskTotalNum;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    taskAdd() {
      this.lineChart1 = true;
      taskAdd({
        departmentId: this.hairpinType3,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
        taskOrUser: this.tabPosition2
      })
        .then((res) => {
          //console.log('预付码新增变化趋势',res)
          this.lineChart1 = false;
          if (res.data != null) {
            this.lineChartData1 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name:
                      this.tabPosition2 == 1
                          ? "总任务"
                          : this.tabPosition2 == 2
                          ? "执行人员"
                          : "",
                  data:
                      this.tabPosition2 == 1
                          ? res.data.totalNum
                          : this.tabPosition2 == 2
                          ? res.data.userNum
                          : [],
                },
                {
                  name:
                      this.tabPosition2 == 1
                          ? "待执行"
                          : this.tabPosition2 == 2
                          ? ""
                          : "",
                  data:
                      this.tabPosition2 == 1
                          ? res.data.status1Num
                          : this.tabPosition2 == 2
                          ? []
                          : [],
                },
                {
                  name:
                      this.tabPosition2 == 1
                          ? "执行中"
                          : this.tabPosition2 == 2
                          ? ""
                          : "",
                  data:
                      this.tabPosition2 == 1
                          ? res.data.status2Num
                          : this.tabPosition2 == 2
                          ? []
                          : [],
                },
                {
                  name:
                      this.tabPosition2 == 1
                          ? "已执行"
                          : this.tabPosition2 == 2
                          ? ""
                          : "",
                  data:
                      this.tabPosition2 == 1
                          ? res.data.status3Num
                          : this.tabPosition2 == 2
                          ? []
                          : [],
                },

              ],
            };
          } else {
            this.lineChartData1 = {
              xAxisData: [],
              seriesData: [],
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    taskCumulative() {
      console.log(this.hairpinType4,'this.hairpinType4')
      this.lineChart2 = true;
      taskCumulative({
        departmentId: this.hairpinType4,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        taskOrUser: this.tabPosition3,
        type: this.radio2,
      }).then((res) => {
        //console.log('任务协同累计变化趋势',res)
        this.lineChart2 = false;
        if (res.data != null) {
          this.lineChartData2 = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                    this.tabPosition3 == 1
                        ? "总任务"
                        : this.tabPosition3 == 2
                        ? "执行人员"
                        : "",
                data:
                    this.tabPosition3 == 1
                        ? res.data.totalNum
                        : this.tabPosition3 == 2
                        ? res.data.userNum
                        : [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "待执行"
                        : this.tabPosition3 == 2
                        ? ""
                        : "",
                data:
                    this.tabPosition3 == 1
                        ? res.data.status1Num
                        : this.tabPosition3 == 2
                        ? []
                        : [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "执行中"
                        : this.tabPosition3 == 2
                        ? ""
                        : "",
                data:
                    this.tabPosition3 == 1
                        ? res.data.status2Num
                        : this.tabPosition3 == 2
                        ? []
                        : [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "已执行"
                        : this.tabPosition3 == 2
                        ? ""
                        : "",
                data:
                    this.tabPosition3 == 1
                        ? res.data.status3Num
                        : this.tabPosition3 == 2
                        ? []
                        : [],
              },
            ],
          };
        } else {
          this.lineChartData2 = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    this.tabPosition3 == 1
                        ? "存管商户发卡金额"
                        : this.tabPosition3 == 2
                        ? "存管商户数量"
                        : "",
                data: [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "保险商户发卡金额"
                        : this.tabPosition3 == 2
                        ? "保险商户数量"
                        : "",
                data: [],
              },
            ],
          };
        }
      });
    },
    taskCityStatistics() {
      this.loading1 = true;
      taskCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    //确保row-key不重复
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      taskCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
    sessionStorage.setItem("initParams", "init");
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
    Toptab,
    PieChart,
  },
};
</script>

<style lang="scss" scoped >
.HomePage {
  .tab-box {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .tab-btns {
      &::v-deep .el-radio-button {
        .el-radio-button__inner {
          width: 214px;
          height: 56px;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:first-child .el-radio-button__inner {
          border-radius: 10px 0 0 10px;
        }
        &:last-child .el-radio-button__inner {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }
  .pie-charts {
    padding: 30px 30px;
    height: 300px;
  }
  // 四色码
  .idiot-one {
    height: 150px;
    margin-bottom: 30px;
    background: white;
    font-size: 20px;
    color: #232a24;
    line-height: 1;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    .item-left{
      width: 295px;
      border-right: 3px solid rgb(236, 236, 236);
      font-size: 24px;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      padding: 10px 0;
      .item-left-top{
        width: 100%;
        span{
          color: #1d67ff;
          font-size: 30px;
        }
        .unit{
          font-size: 18px;
        }
      }
    }
    .item-center{
      width: 190px;
      margin-right: 25px;
      text-align: center;
      padding: 22px 0 15px;
      &>div{
        font-size: 24px;
        p{
          margin-top: 16px;
          color: #f9a100;
          font-size: 30px;
          span{
            font-size: 18px;
          }
        }
      }
    }
    .item-right{
      flex: 1;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      .item-right-top{
        width: 100%;
        span{
          color: #626262;
        }
      }
    }


  }
  .pie-rwfb {
    width: 100%;
    min-height: 100px;
    padding: 0 30px 30px 30px;
    font-weight: 200;
    font-size: 24px;
    .pie-rwfb-top {
      width: 100%;
      height: 60px;
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      .pie-rwfb-top-1,
      .pie-rwfb-top-2 {
        width: 170px;
        height: 60px;
        display: flex;
        align-items: center;
      }
      .pie-rwfb-top-indent {
        text-indent: 20px;
      }
      .pie-rwfb-top-2 {
        width: 140px;
      }
    }
    .pie-rwfb-box {
      display: flex;
      flex-wrap: wrap;
      .pie-rwfb-val {
        width: 50%;
        // height: 60px;
        display: flex;
        border-bottom: 1px dashed #e1e1e1;
        .pie-rwfb-val-name,
        .pie-rwfb-val-val {
          width: 170px;
          // height: 60px;
          padding: 10px 0;
          display: flex;
          align-items: center;
          .pie-rwfb-val-icon {
            width: 8px;
            height: 8px;
            margin: 0 8px;
          }
        }
        .pie-rwfb-val-val {
          width: 140px;
        }
      }
    }
    .pie-rwfb-val {
      width: 100%;
      // height: 60px;
      display: flex;
      border-bottom: 1px dashed #e1e1e1;
      .pie-rwfb-val-name,
      .pie-rwfb-val-val {
        width: 170px;
        padding: 10px 0;
        // height: 60px;
        display: flex;
        align-items: center;
        .pie-rwfb-val-icon {
          width: 8px;
          height: 8px;
          margin: 0 8px;
        }
      }
      .pie-rwfb-val-val {
        width: 140px;
      }
    }
  }
  .kselect {
    position: absolute;
    left: 150px;
    top: 280px;
  }
}
</style>
