<template>
  <div ref="chart" :style="{ height: height, width: width }"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        xAxisData: [],
        seriesData: [],
      },
    },
    legendData: {
      type: Array,
      default: [],
    },
    units: {
      type: Array,
      default: [],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      color: [
        "#ccc",
        "rgba(255,78,0,0.7)",
        "rgba(255,234,0,0.7)",
        "rgba(90,244,177,0.7)",
        "rgba(1,27,57,0.7)",
      ],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      this.chart.clear();
      let arr = _this.chartData.seriesData || [];
      //console.log('--------------->',arr)
      //if(arr.length>0){
      let seriesData = [];
      arr.forEach((item, i) => {
        seriesData.push({
          name: item.name,
          type: "line",
          showSymbol: false,
          symbol: "circle",
          smooth: true,
          lineStyle: {
            color: item.color ? item.color : _this.color[i],
            width: 1,
          },
          itemStyle: {
            color: item.color ? item.color : _this.color[i],
          },
          // areaStyle: {
          //   color: {
          //     type: "linear",
          //     x: 0,
          //     y: 0,
          //     x2: 0,
          //     y2: 1,
          //     colorStops: [
          //       {
          //         offset: 0,
          //         color: item.color?item.color:_this.color[i], // 0% 处的颜色
          //       },
          //       {
          //         offset: 1,
          //         color: "#0000", // 100% 处的颜色
          //       },
          //     ],
          //     global: false, // 缺省为 false
          //   },
          // },
          data: item.data,
        });
      });
      // 绘制图表
      this.chart.setOption({
        tooltip: {
          transitionDuration: 0,
          trigger: "axis",
          padding: 20,
          backgroundColor: "rgba(238,238,238,0.6)",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dashed",
              color: "rgba(102,163,215,0.3)",
            },
          },
          textStyle: {
            color: "#232a24",
          },
          formatter: function (params) {
            //console.log(params)
            let html = "";
            html += params[0].name + "<br>";
            params.forEach((res, i) => {
              html +=
                res.marker +
                res.seriesName +
                ":<span>" +
                res.value +
                "</span>" +
                _this.units[i] +
                "<br>";
            });
            return html;
          },
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "5%",
          right: "7%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        // legend: {
        //  top: "5%",
        //   right: "5%",
        //   itemGap: 5,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   icon: "circle",
        //   data: _this.legendData,
        //   textStyle: {
        //     fontSize: 12,
        //     color: "#232a24",
        //   },
        // },
        xAxis: {
          data: _this.chartData.xAxisData,
          splitNumber: 7,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ececec",
            },
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#ececec",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 12,
              color: "#232a24",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            scale: true,
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#ececec",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "#232a24",
              },
            },
            axisTick: {
              show: false,
            },
          },
          {
            show: false,
          },
        ],
        // '资源申请数','已提供资源数','需求项超时数'
        series: seriesData,
      });
      // }else{
      //   this.chart.setOption({})
      // }
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
